<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-gavel"></i><strong> Travaux sur les parcelles</strong>
            </div>
            <div class="text-center" v-if="fetching">
              <b-spinner></b-spinner>
              <br>
              Chargement...
            </div>
            <div v-else-if="tasks.length">
              <step-progress v-for="task in tasks" :data="task" :staff="staff"
                             @update="onStepUpdate"></step-progress>
            </div>
            <p class="text-center" v-else>Aucun travail dans cette exploitation.</p>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, Regex, Toast, asset, Storage, str} from "../../../helpers/index";

  export default {
    name: "ExploitationEmployees",
    title: "PIA - Travaux",
    data() {
      return {
        staff: [],
        tasks: [],
        error: null,
        fetchingStaffList: false,
        fetchingTasksList: false
      }
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      fetching() {
        return this.fetchingStaffList || this.fetchingTasksList
      }
    },
    created() {
      this.fetchingStaffList = true
      Api.get('/exploitation/staff/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.staff = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingStaffList = false
        })

      this.fetchingTasksList = true
      Api.get('/exploitation/task/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.tasks = res.data.data.filter(task => task.culture && task.cultureSteps.length)
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingTasksList = false
        })
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    methods: {
      onStepUpdate(step) {
        for(let i=0; i<this.tasks.length; i++)
        {
          if(this.tasks[i].parcel.id === step.parcelId)
          {
            for(let j=0; j<this.tasks[i].cultureSteps.length; j++)
            {
              if(this.tasks[i].cultureSteps[j].id === step.cultureStepId)
              {
                this.tasks[i].cultureSteps[j].responsible = step.responsible
                this.tasks[i].cultureSteps[j].progression = step.progression
                this.tasks[i].cultureSteps[j].deadline = step.deadline
                break
              }
            }
            break
          }
        }
      }
    }
  }
</script>
